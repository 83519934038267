import React from "react"
import {
    Box,
    Flex,
    Text,
    IconButton,
    Stack,
    Collapse,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    useColorMode,
    Link
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    SunIcon,
    MoonIcon,
} from '@chakra-ui/icons';
import { FaInstagram } from "react-icons/fa";
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function WithSubnavigation() {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode()
    const bgColor = useColorModeValue('white', 'black');

    return (
        <Box position="fixed" w="100%" zIndex={1}>
            <Flex
                bg={isOpen ? bgColor : 'transparent'}
                color={useColorModeValue('gray.600', 'white')}
                transition={'all .3s ease'}
                minH={'60px'}
                py={{ base: 3 }}
                px={{ base: 6 }}
                align={'center'}>
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
                    <Text
                        textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                        fontFamily={'heading'}
                        color={useColorModeValue('gray.800', 'white')}>
                        <Link as={GatsbyLink} to="/" onClick={onClose}>
                            <StaticImage placeholder={null} src="../images/logomark_render.png" display="block" height="50" alt="JJR" />
                        </Link>
                    </Text>

                    <Flex display={{ base: 'none', md: 'flex' }} alignItems="center" ml={5}>
                        <DesktopNav />
                    </Flex>
                </Flex>

                <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={1}>
                    <IconButton
                        as={Link}
                        fontSize={'sm'}
                        bg="none"
                        _hover={{ bg: 'none' }}
                        color={colorMode === 'light' ? "gray.500" : "gray.200"}
                        fontWeight={400}
                        href={'https://instagram.com/jjr.jewelry'}
                        isExternal
                        icon={<FaInstagram transform="scale(1.15)" />}>
                    </IconButton>
                    <IconButton
                        as={'a'}
                        fontSize={'sm'}
                        fontWeight={400}
                        variant={'link'}
                        href={'#'}
                        onClick={toggleColorMode}
                        icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}>
                    </IconButton>
                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav onCloseMobileNav={onClose} />
            </Collapse>
        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'black');

    return (
        <Stack direction={'row'} spacing={3}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Link
                                as={GatsbyLink}
                                p={2}
                                to={navItem.href ?? '#'}
                                fontWeight={500}
                                letterSpacing={1}
                                opacity="0.8"
                                color={linkColor}
                                _hover={{
                                    textDecoration: 'none',
                                    color: linkHoverColor,
                                }}>
                                {navItem.label}
                            </Link>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <Link
            as={GatsbyLink}
            to={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'pink.400' }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = ({ onCloseMobileNav }) => {
    return (
        <Stack
            position="fixed"
            borderBottom="1px solid"
            borderBottomColor={useColorModeValue('gray.200', 'gray.800')}
            zIndex="100"
            w="100%"
            bg={useColorModeValue('white', 'black')}
            p={4}
            textAlign="center"
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem onCloseMobileNav={onCloseMobileNav} key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href, onCloseMobileNav }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Link
                onClick={onCloseMobileNav}
                py={2}
                as={GatsbyLink}
                to={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={500}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Link>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} to={child.href}
                                as={GatsbyLink}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack >
    );
};

const NAV_ITEMS = [
    {
        label: 'Inquire',
        href: '/contact',
    },
    {
        label: 'Collection',
        href: '/collection',
    }
];
